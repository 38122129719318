@import "config";

.dashboardPage {
  .contentWrapper {
    display: flex;
    flex: auto;
    flex-direction: column;
    width: 100%;
    padding: 0 $contentDefaultIndent * 2;
    :global(.row) {
      flex: 1;
      flex-wrap: nowrap;
      margin: 0;
      + div {
        margin-top: $contentDefaultIndent * 2;
      }
      :global(.col) {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        padding: 0;
        + div {
          margin-left: $contentDefaultIndent * 2;
        }
      }
    }
    .widgetsColumn {
      flex: 1;
      max-width: 40em;
      .turnoverWidget {
        .turnover {
          margin: auto;
          font-size: 2em;
          font-weight: 500;
          line-height: 1;
          &[data-single] {
            line-height: 2.5;
          }
        }
        .trends {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: $contentDefaultIndent * 2;
          > div {
            &:first-child {
              width: 30%;
            }
            &:last-child {
              margin-left: $contentDefaultIndent * 2;
            }
          }
        }
      }
    }
    .chartColumn {
      flex: 2;
    }
    .simplifiedLayoutColumn {
      flex: 2;
      > :global(.row) {
        &:first-child {
          flex: 0;
        }
      }
      .widgetsColumn {
        max-width: none;
      }
    }
    .noDataColumn {
      flex: 2;
    }
  }
}
