@import "config";

.dashboardFilter {
  > form {
    display: flex;
    align-items: center;
    height: 2.4em;
    > div {
      flex: 0.8;
      height: 100%;
      button {
        padding: 0 0.7em;
      }
    }
    > button {
      height: 100%;
      padding: 0 0.7em;
    }
    > div,
    > button {
      + div,
      + button {
        margin-left: $contentDefaultIndent;
      }
    }
    button {
      &:global(.btn) {
        &-secondary {
          &[disabled] {
            border-color: $uiBorderColor;
            background: $uiBorderColor;
            color: $uiDarkColor;
          }
        }
      }
    }
  }
}
