@import "config";

.dashboardWidget {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: $contentDefaultIndent;
  white-space: nowrap;
  &.highlight {
    background-image: linear-gradient(to bottom, $uiHighlightColor, darken($uiHighlightColor, 5%));
    color: $uiWhiteColor;
    .titleContainer {
      background-image: none;
    }
  }
  &.alignedText {
    .content {
      > div {
        display: flex;
        align-items: center;
        > span {
          &:first-child {
            margin-right: 0.3em;
          }
          &:last-child {
            margin-left: auto;
            a {
              color: $uiDarkColor;
              text-decoration: underline;
              &:hover {
                color: $uiHighlightColor;
              }
            }
          }
        }
      }
    }
  }
  > div {
    padding: $contentDefaultIndent * 2;
    overflow: hidden;
  }
  .titleContainer {
    display: flex;
    align-items: center;
    border-radius: $contentDefaultIndent;
    background-image: linear-gradient(to bottom, $uiBackgroundColor, $uiWhiteColor);
    .title {
      margin-right: $contentDefaultIndent;
      font-size: 1.4em;
      font-weight: 500;
    }
    .date {
      margin-bottom: -0.3em;
      margin-left: auto;
      opacity: 0.65;
      font-size: 1.2em;
      svg {
        margin-top: -0.2em;
      }
    }
  }
  .content {
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-evenly;
    padding-top: 0;
    font-size: 1.1em;
  }
}
