.cashFlowChart {
  height: 100%;
  min-height: 60vh;
  :global(.apexcharts) {
    &-tooltip {
      transition: none !important;
      line-height: 1;
    }
    &-legend {
      // stylelint-disable-next-line
      [rel="1"] {
        display: none;
      }
      span {
        cursor: default !important;
      }
    }
  }
}
